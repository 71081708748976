<template>
  <div id="Explore" class="container">
    <el-row type="flex">
      <el-col
        ><div class="choose">
          {{ $t("explore.exploreCollections") }}
        </div></el-col
      >
    </el-row>
    <div v-if="nftList.length == 0" class="empty">
      <img src="@/assets/images/empty.png" alt="" />
      <p>{{ $t("myNfts.noNfts") }}</p>
    </div>
    <el-row v-else style="overflow: auto">
      <el-col v-for="(item, index) in nftList" :key="index" :lg="12" :md="12">
        <el-card
          class="item"
          :body-style="{ padding: '0px', display: 'flex' }"
          shadow="hover"
          @click.native="exploreDetail(item.site)"
        >
          <div class="img-box">
            <el-image :src="item.logo" fit="cover" class="image" lazy>
              <div slot="error">
                <img src="@/assets/images/default.png" alt="" class="image" />
              </div>
            </el-image>
            <div
              v-if="
                item.explorerStatus == 0 &&
                item.countDown <= 7 * 24 * 60 * 60 * 1000
              "
              class="countDown"
            >
              <div class="countDown-item">
                <span class="num">{{ item.days || "00" }}</span>
                <span class="unit">{{ $t("explore.days") }}</span>
              </div>
              <div class="countDown-item">
                <span class="num">{{ item.hours || "00" }}</span>
                <span class="unit">{{ $t("explore.hours") }}</span>
              </div>
              <div class="countDown-item">
                <span class="num">{{ item.minutes || "00" }}</span>
                <span class="unit">{{ $t("explore.minutes") }}</span>
              </div>
              <div class="countDown-item">
                <span class="num">{{ item.seconds || "00" }}</span>
                <span class="unit">{{ $t("explore.seconds") }}</span>
              </div>
            </div>
            <span
              v-else-if="item.explorerStatus == 0"
              class="explorer-status upcoming"
              >{{ $t("explore.upcoming") }}</span
            >
            <span
              v-if="item.explorerStatus == 1"
              class="explorer-status going"
              >{{ $t("explore.going") }}</span
            >
            <span
              v-if="item.explorerStatus == 2"
              class="explorer-status ended"
              >{{ $t("explore.ended") }}</span
            >
          </div>
          <div class="right-box">
            <div class="nft-name">{{ item.name }}</div>
            <div class="nft-tips">{{ item.description }}</div>
            <el-divider></el-divider>
            <div class="collection-info">
              <div class="info-item">
                <span>
                  <i class="el-icon-price-tag"></i>
                  <span class="info-tit">{{ $t("explore.price") }}</span>
                </span>
                <span class="bold" v-if="item.price == '0'">Free</span>
                <span class="bold" v-else>{{
                  item.price + " " + item.unit
                }}</span>
              </div>
              <div class="info-item">
                <span>
                  <i class="el-icon-bangzhu"></i>
                  <span class="info-tit">{{ $t("explore.total") }}</span>
                </span>
                <span class="bold">{{ item.total }}</span>
              </div>
              <div class="info-item">
                <span>
                  <i class="el-icon-time"></i>
                  <span class="info-tit">{{ $t("explore.date") }}</span>
                </span>
                <span class="bold">
                  {{ new Date(item.mintStartAt).format() }}
                </span>
              </div>
            </div>
            <!-- <el-button class="view" @click="exploreDetail(item.contractAddress)"
              >View</el-button
            > -->
          </div>
        </el-card>
      </el-col>
    </el-row>
  </div>
</template>
<script>
import { getExplorerList } from "@/api";

export default {
  name: "Explore",
  data() {
    return {
      nftList: [],
      page: 1,
      pageSize: 15,
      resData: {
        code: 200,
        message: "",
        data: {
          count: 2,
          page: 1,
          list: [
            {
              name: "Meta N1",
              site: "https://meta-n1.mintbox.vip/",
              description:
                "스타일 매거진 ‘앳스타일’(@star1)이 창간 10주년을 기념해 표지를 장식한 톱스타 장근석을 NFT로 재현!\n26살 장근석의 앳된 모습과 당시 인터뷰를 그대로 담아 \n오랫동안 만나지 못했던 팬들에게 보내는 특별한 선물!\nNFT 마켓플레이스를 중심으로 소속 아티스트와 팬이 직접 소통하고 교류할 수 있는 팬덤 커뮤니티 기반 NFT 생태계\n그 첫번째 이야기 meta N1.",
              mintStartAt: 1653994800000,
              mintEndAt: 1654081200000,
              logo: "https://mint-box-temp.4everland.store/blind/Logo.png",
              contractAddress: "0x2730Ab0B97dBB941d0B633385BC88A6BfA39D0E4",
              explorerStatus: 2,
              countDown: 0,
              price: "0",
              unit: "",
              total: 199,
            },
            {
              name: "MintSmith 1st Gen",
              site: "https://mintsmith-1st-gen-ZlRprRW.mint.mintbox.vip/",
              description:
                "전 세계 NFT시장을 씹어먹을 그들이 왔다!\n\n한국 최대의 블록체인 VC 힐스톤에서 만든 \n9인의 ‘1세대 민트 스미스’가 탄생했습니다. \n\n민트 스미스는 NFT씬에 가장 큰 대부가 될 것입니다. ",
              mintStartAt: 1653231600000,
              mintEndAt: 1653318000000,
              logo: "https://image.mintbox.vip/0x7e885ed93ea4e3c42d6364c05706584a564e1bfa/2022-05-23/4fb6c147113b92f17faa037f547355d2.png",
              contractAddress: "0xeeDA5c4cD58E53732d565a8C16344Ca804E59096",
              explorerStatus: 2,
              countDown: 0,
              price: "0",
              unit: "USDT",
              total: 9,
            },
          ],
        },
      },
    };
  },
  methods: {
    getList() {
      this.nftList = this.resData.data.list;

      // getExplorerList(this.page, this.pageSize).then((res) => {
      //   this.nftList = res.data.list;
      //   this.setCountDown();
      // });
    },
    exploreDetail(site) {
      window.open(site);
    },
    setCountDown() {
      this.nftList.map((item, index) => {
        if (item.countDown > 0) {
          let timer = setInterval(() => {
            this.countDownTimer(item.countDown, index);
            item.countDown -= 1000;
            if (item.countDown <= 0) {
              item.countDown = 0;
              clearInterval(timer);
              item.explorerStatus = 1;
            }
          }, 1000);
        }
      });
    },
    countDownTimer(countDown, i) {
      let leftTime = countDown;
      let days = parseInt(leftTime / 1000 / 60 / 60 / 24, 10);
      let hours = parseInt((leftTime / 1000 / 60 / 60) % 24, 10);
      let minutes = parseInt((leftTime / 1000 / 60) % 60, 10);
      let seconds = parseInt((leftTime / 1000) % 60, 10);
      this.nftList[i].days = this.checkTime(days);
      this.nftList[i].hours = this.checkTime(hours);
      this.nftList[i].minutes = this.checkTime(minutes);
      this.nftList[i].seconds = this.checkTime(seconds);
    },
    checkTime(i) {
      if (i < 10) {
        i = "0" + i;
      }
      return i;
    },
  },
  created() {
    this.getList();
  },
};
</script>
<style lang="less" scoped>
#Explore {
  .choose {
    color: #000;
    font-size: 40px;
    font-weight: bold;
    margin: 80px 0;
    text-align: center;
  }
  .empty {
    text-align: center;
    width: 100%;
    padding-top: 160px;
    img {
      max-width: 310px;
    }
    p {
      font-size: 14px;
    }
  }
  .item {
    margin: 10px;
    // height: 220px;
    cursor: pointer;
    .img-box {
      position: relative;
      .image {
        width: 200px;
        height: 200px;
        display: block;
      }
      .explorer-status {
        position: absolute;
        width: 68px;
        height: 28px;
        line-height: 28px;
        border-radius: 6px;
        top: 9px;
        right: 13px;
        color: #fff;
        font-size: 12px;
        text-align: center;
      }
      .upcoming {
        background: linear-gradient(
          180deg,
          #b2dbf0 0%,
          #81add5 81%,
          #7598c5 100%
        );
        box-shadow: 0px 2px 4px 0px #9a98ce;
        border: 1px solid;
      }
      .going {
        background: linear-gradient(
          180deg,
          #deb2f0 0%,
          #c991e3 80%,
          #d4ade6 100%
        );
        box-shadow: 0px 2px 4px 0px #bd98ce;
        border: 1px solid;
      }
      .ended {
        background: #cbcdd6;
      }
      .countDown {
        width: 180px;
        height: 45px;
        background: rgba(0, 0, 0, 0.3);
        border-radius: 5px;
        position: absolute;
        top: 10px;
        left: 0;
        right: 0;
        margin: auto;
        display: flex;
        justify-content: space-around;
        .countDown-item {
          text-align: center;
          color: #fff;
          width: 45px;
          .num {
            display: block;
            font-size: 18px;
            font-weight: bold;
          }
          .unit {
            display: block;
            font-size: 12px;
            font-weight: normal;
            transform: scale(0.7);
          }
        }
      }
    }

    .right-box {
      width: 100%;
      padding: 20px 30px 0 30px;
      background-color: #fff;
      text-align: left;
      flex: 1;
    }
    .nft-name {
      max-width: 230px;
      color: #000;
      font-size: 16px;
      font-weight: bold;
      margin-bottom: 6px;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
    .nft-tips {
      color: #999;
      font-size: 12px;
      display: -webkit-box;
      -webkit-box-orient: vertical;
      -webkit-line-clamp: 2;
      overflow: hidden;
      word-break: break-all;
    }
    .el-divider--horizontal {
      margin: 10px 0;
    }
    .collection-info {
      .info-item {
        display: flex;
        justify-content: space-between;
        font-size: 12px;
        color: #999999;
        margin-bottom: 15px;
        .info-tit {
          margin-left: 10px;
        }
        .bold {
          font-weight: bold;
          color: #000;
        }
      }
    }
  }
}
</style>
